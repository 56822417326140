/* eslint-disable no-unused-expressions */
import React from "react"
import Helmet from "react-helmet"
import { graphql, withPrefix } from 'gatsby'
import Layout from "../components/layout"

class PythonIn100Chunks extends React.Component {
    render() {
        return (
            <Layout>
                <Helmet>
                    <title>Python in 100 Chunks Guide — {this.props.data.site.siteMetadata.title}</title>
                    <meta name="description" content={"Contact page of " + this.props.data.site.siteMetadata.description} />
                    <script src="https://documentcloud.adobe.com/view-sdk/main.js" />
                    <script src={withPrefix('scripts/embed-pdf.js')} type="text/javascript" />
                </Helmet>
                <div className="flex justify-around">
                    <span className="inline-flex rounded-md shadow-sm">
                        <button id="loadingButton" type="button" className="inline-flex items-center px-4 py-2 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-green-600 hover:bg-rose-500 focus:border-rose-700 active:bg-rose-700 transition ease-in-out duration-150 cursor-not-allowed" disabled="">
                            <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                    Loading
                  </button>
                    </span>
                </div>
                <div id="adobe-dc-view" className="h-96"></div>
            </Layout >
        )
    }
}

export default PythonIn100Chunks

export const pageQuery = graphql`
  query PythonIn100ChunksQuery{
                        site {
                        siteMetadata {
                        title
        description
      }
    }
  }
`